import './App.css';
import logo from './logo.png';

import LinkedIn from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Grid, Typography } from '@mui/material';

function App() {
  return (
    <>
      <Grid container spacing={3} style={{ padding: '0 5rem', marginTop: '25px' }} alignItems='center'>
        <Grid item xs={12} sm={6} md={6}>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'no-wrap' }}>
            <img src={logo} alt="logo" width="75px" />
            <h3 style={{ margin: '0 30px', color: 'white' }}>Anymus</h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <a style={{ color: 'white' }} href='https://instagram.com/anymushealth?utm_medium=copy_link' target="_blank" rel="noreferrer"><InstagramIcon /></a>
            <a style={{ color: 'white' }} href='https://www.linkedin.com/company/anymus/?viewAsMember=true' target="_blank" rel="noreferrer"><LinkedIn /></a>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems='center' flexDirection='column' style={{ padding: '5rem' }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h3" component="div" gutterBottom>
            Terapia en línea
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: '400px', textAlign: 'center', width: '100%' }}>
          <Typography variant="overline" component="div" gutterBottom >
            Anymus es una suscripción de salud mental que proporciona a los pacientes un acceso continuo y completo a la atención en línea y a la gestión de la medicación por una tarifa mensual.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', maxWidth: '600px', width: '100%' }}>
          <div>
            <input id='email' type="email" placeholder="Ingresa tu e-mail aquí" />
            <button class="ml-i-btn">Suscribirse</button>
          </div>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', maxWidth: '600px', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px' }}>
            <Typography variant="overline" component="div" gutterBottom>
              SUSCRIBETE A NUESTRO ACCESO ANTICIPADO PARA RECIBIR NUESTRAS ÚLTIMAS NOVEDADES
            </Typography>
          </div>
        </Grid>
      </Grid>
      {/*  <Grid container spacing={3} justifyContent='space-between'>
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <div>
            <h1 style={{ padding: '2.5rem', fontSize: '57px' }}>Terapia en línea.</h1>
            <p style={{ textAlign: 'center' }}>
              Anymus es una suscripción de salud mental que proporciona a los pacientes un acceso continuo y completo a la atención en línea y a la gestión de la medicación por una tarifa mensual.
            </p>
            <div style={{ margin: 'auto' }} >
              <input id='email' type="email" placeholder="Ingresa tu e-mail aquí" />
              <button class="ml-i-btn">Suscribirse</button>
            </div>
            <p class="m-lft-warning">
              <span class="ml-w-span"><EmailIcon /></span>Suscribete a nuestro acceso anticipado para recibir nuestrar últimas novedades
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <img src={image} alt="mujer dibujada" style={{ height: '400px' }} />
        </Grid>
        <Grid item xs={12} justifyContent='center'>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
            <InstagramIcon />
            <TwitterIcon />
            <FacebookIcon />
          </div>
        </Grid>
      </Grid> */}

    </>
  );
}

export default App;
